import * as React from 'react';
import Styles from './contact-header.module.scss';

export default props => {
  return (
    <div className="container">
      <div className="row pt-5">
        <div className="col-12 col-md-6 d-none d-md-block">
          <img className={`${Styles.headerImage}`} alt="Praxis Eingang" src="/images/contact/praxis-eingang.jpg"/>
        </div>
        <div className="col-12 col-md-6 pl-3 pl-md-5">
          <h1 className="text-uppercase">
            So erreichst du mich
          </h1>
          <p className="pt-4">
            Anna Weber-Nilges<br/>
            Steinweg 30<br/>
            57627 Hachenburg
          </p>
          <div className="d-flex flex-row pt-4">
            <div className="flex-column">

              <div>
                <a className="unstyled d-flex align-items-center" href="tel:+4926629502797">
                  <i className={`material-icons color-green`}>phone</i>
                  <span className={`color-black font-weight-light pl-3`}>+49 2662 9502 797</span>
                </a>
              </div>

              <div className="my-2">
                <a className="unstyled d-flex align-items-center" href="tel:+4917634252129">
                  <i className={`material-icons color-green`}>smartphone</i>
                  <span className={`color-black font-weight-light pl-3`}>+49 176 3425 2129</span>
                </a>
              </div>

              <div>
                <a className="unstyled d-flex align-items-center" href="mailto:info@psychotherapie-weber.net">
                  <i className={`material-icons color-green`}>mail</i>
                  <span className={`color-black font-weight-light pl-3`}>info@psychotherapie-weber.net</span>
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    )
}
