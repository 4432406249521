import * as React from "react";
import Layout from "../components/global/layout/layout";
import {graphql} from "gatsby";
import ContactHeader from "../components/contact/contact-header/contact-header";

export const pageQuery = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        title
        description
        titleSuffix
        titleDivider
      }
    }
  }
`;

export default props => {
  return (
    <Layout title={props.data.site.siteMetadata.title}
            description={props.data.site.siteMetadata.description}
            titleSuffix="Kontakt"
            titleDivider={props.data.site.siteMetadata.titleDivider}>
      <ContactHeader/>
      <div className="container">
        <div className="row mt-5 mb-2">
          <div className="col-12">
            <p className={`pt-2 pb-2`}>
              Ruf mich an, schreib mir eine email oder sms. Ich melde mich schnellstmöglich zurück.
              Meine offiziellen Telefonsprechzeiten für die Vergabe von Erstterminen sind zusätzlich folgende unter der Nummer <a className="unstyled" href="tel:+4917634252129">0176 3425 2129</a>:
            </p>
            <div className="d-flex flex-row font-weight-bold">
              <div className="flex-column">
                <div>Mo/Di/Do/Fr:</div>
                <div>Do:</div>
              </div>
              <div className="flex-column pl-4">
                <div>08:35 - 09:00 Uhr</div>
                <div>14:50 - 16:30 Uhr</div>
              </div>
            </div>
            <p className={`pt-4`}>
              Du findest mich im Steinweg 30 in Hachenburg. Die Plätze vor dem Haus sind für die Besucher der Praxen reserviert. Der Weg führt eine Rampe hoch, an einer krankengymnastischen Praxis vorbei, dann biegst du zweimal links ab. Der Eingang liegt im Hinterhaus.
            </p>
            <p className={`pt-2`}>
              Ich bin berechtigt, mit allen gesetzlichen und privaten Krankenkassen abzurechnen. Du reichst  lediglich deine Krankenkassenkarte bzw. die deines Kindes beim Ersttermin ein. Eine Überweisung ist nicht notwendig.
            </p>
            <p className={`pt-2`}>
              Als Kinder- und Jugendlichenpsychotherapeutin behandle ich Kinder im Grunde ab Geburt. Die jüngsten Kinder sind in der Regel jedoch mindestens 4 Jahre alt. Junge  Erwachsene kann ich bis über das 21. Lebensjahr hinaus therapeutisch begleiten, wenn diese vor dem 21. Lebensjahr eine Therapie bei mir begonnen haben.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
